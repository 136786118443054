<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-card>
            <v-card-title>Reset Report</v-card-title>
            <v-card-text class="pb-0 my-1">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span class="text-subtitle-1">Are you sure that you want to reset data <span v-text="getContentText()"></span> to default?</span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="px-6">
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" :ripple="false" @click="close">Later</v-btn>
                <v-btn text color="primary" :loading="loading" :ripple="false" @click="save">Reset</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'CampaignGenerateDialog',
    data() {
        return {
            loading: false,
            dialog: false,
            object: {
                text: '',
                value: ''
            },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
        save: function() {
			this.loading = true
            const object = { report: this.object.value, report_date: this.object.report_date }
            this.$store.dispatch('epanel/report/generateReportCampaignOutlet', object).then((response) => {
                this.$emit('generated', {object: response.data.object, message: response.message})
                this.close()
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
		},
        getContentText: function() {
            if(this.object.outlet){
                return " for outlet - " + this.object.outlet + " for " + this.object.text
            }

            return " for all outlets for " + this.object.text
        }
    }
}

</script>