<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Outlet Campaign Sales Reports</p>
                <p class="text-title mb-0">You do not have permission to view outlet campaign sales report</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-6">Outlet Campaign Report</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense hide-details clearable label="Outlet Name" v-model="filters.outlet"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-dialog persistent ref="campaignMonthModal" width="320px" :return-value.sync="filters.campaign_month" v-model="campaignMonthModal">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="Campaign Month" v-bind="attrs" v-on="on" v-model="filters.campaign_month"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable color="primary" type="month" v-model="filters.campaign_month">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="campaignMonthModal = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.campaignMonthModal.save(filters.campaign_month)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="search">
                                    <v-icon small left>mdi-magnify</v-icon> View
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openGenerateDialog({}, -1)" v-if="permissions.can_reset">Reset to default</v-btn>
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openDownloadDialog" v-if="permissions.can_download">
                        <v-icon small left>mdi-download</v-icon> Download
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-center font-weight-bold">Summary of Manhours Deployed <span v-text="object.campaign_month"></span></p>
                    <v-data-table dense hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span :class="{'pointer blue--text text-decoration-underline': item.can_reset && object.is_generable }" v-text="item.outlet" @click="openGenerateDialog(item, index)"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.man_days"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_sales"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.day_sales"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span :class="{'pointer blue--text text-decoration-underline': item.can_edit && item.day_cost !== '-' }" v-text="item.day_cost" @click="openUpdateDialog(item, index)"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_cost"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.roi"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.roi_percentage"></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-row v-if="!loading && pagination.total_pages > 1">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                    </v-col>
                </v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">Note:<br>
                    1. Each outlet's data will be generated on 1st of every next month. Eg: The data in March will be generated on 1st of April.<br>
                    2. Data can be re-generate for the latest 2 months only.<br>
                    3. Man-day Cost/Day will be reset to 120/man-day for each generates.<br>
                    </small>
                </v-col>
            </v-row>
        </template>
        <update-dialog ref="updateDialog" @updated="updateReport"></update-dialog>
        <generate-dialog ref="generateDialog" @generated="updateReport"></generate-dialog>
        <download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { makeDate } from '@/utils/dates'
import CampaignDownloadDialog from './CampaignDownloadDialog.vue'
import CampaignUpdateDialog from './CampaignUpdateDialog.vue'
import CampaignGenerateDialog from './CampaignGenerateDialog.vue'
import SimpleActionDialog from '../../../../shared/SimpleActionDialog.vue'
import MessageNotifier from '../../../../shared/MessageNotifier.vue'

export default {
    name: 'CampaignReportList',
    components: {
        simpleActionDialog: SimpleActionDialog,
        updateDialog: CampaignUpdateDialog,
        generateDialog: CampaignGenerateDialog,
        downloadDialog: CampaignDownloadDialog,
        messageNotifier: MessageNotifier,
    },
    data() {
        return {
            loading: true,
            campaignMonthModal: null,
            selectedIndex: -1,
            headers: [],
            items: [],
            pagination: {},
            filters: {
                outlet: '',
                campaign_month: this.getTodayMonth(),
                page: 1
            },
            object: {
                campaign_month: '',
                is_generable: false
            },
            permissions: {
                can_list: false,
                can_reset: false,
                can_download: false
            }
        }
    },
    computed: {
        updateDialog() {
            return this.$refs.updateDialog
        },
        generateDialog() {
            return this.$refs.generateDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        },
        notifier() {
            return this.$refs.notifier
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            const filters = copyObject(this.filters)
            filters.campaign_month = this.filters.campaign_month + '-01'
            this.$store.dispatch('epanel/report/getReportCampaignOutlet', filters).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.object = response.data.object
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getTodayMonth: function(){
            var today = new Date()
            return makeDate(today.getFullYear(), today.getMonth() + 1, 1).toString().substring(0,7)
        },
        search: function() {
            this.filters.page = 1
            this.get()
        },
        reset: function() {
            this.filters = { outlet: '', year: new Date().getFullYear().toString(), month: (new Date().getMonth() + 1).toString(), page: 1 }
        },
        openUpdateDialog: function(item, index) {
            if(item.can_edit && item.day_cost !== '-') {
                this.selectedIndex = index
                this.updateDialog.updateObject({ report: item.id, current_cost: item.day_cost})
                this.updateDialog.open()
            }
        },
        openGenerateDialog: function(item, index) {
            if(this.permissions.can_reset && this.object.is_generable){
                this.selectedIndex = index
                this.generateDialog.updateObject({ outlet: index >= 0?item.outlet:'', text: this.object.campaign_month,
                                                   value: index >= 0?item.id:'', report_date: this.filters.campaign_month + '-01' })
                this.generateDialog.open()
            }
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download){
                this.downloadDialog.updateObject({ campaign_month: this.filters.campaign_month + '-01'})
                this.downloadDialog.open()
            }
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateReport: function(item){
            if(this.selectedIndex === -1){
                this.get()
            }else{
                this.$set(this.items, this.selectedIndex, item.object)
            }
            this.openNotifier(item.message)
        }
    }
}

</script>